<template>
  <div class="liff-staff-list">

    <div class="liff-staff-list__content" :class="{ 'need-bottom': !!branchId }">
      <b-card v-for="(level, index) in branchLevel" :key="level" class="liff-staff-list__card">
        <SharedSelect
            class="mt-2"
            v-model="temporaryBranch[index]"
            :options="providerOptions[level].options"
            :placeholder="displayPlaceholder"
        />
      </b-card>
    </div>

    <div class="text-center my-2" v-if="fetching">
      <b-spinner class="align-middle spinner-border"></b-spinner>
    </div>

    <div class="liff-staff-list__confirm">
      <div class="s-flex s-justify-between s-items-center" style="gap: 8px;">
        <button
          class="s-btn s-liff-theme-btn s-btn-sm"
          type="button"
          :disabled="!isLastBranch"
          @click="nextStep">
        下一步
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  // import { BIconCheckCircleFill } from 'bootstrap-vue';
  // import bookingPresetApi from '@/apis/liff/v2/booking-preset';
  import SharedSelect from "@/components/Page/Liff/Shared/Select";
  import branchMixin from "@/mixins/Dashboard/branches";
  import reservationMixin from "@/mixins/liff/reservation";
  import staffApi from "@/apis/liff/v2/staff";
  import _ from "lodash";

  export default {
    mixins: [branchMixin, reservationMixin],
    components: {
      // BIconCheckCircleFill,
      SharedSelect
    },
    computed: {
      branchProvider() {
        return 'waltily.branch'
      },
      displayPlaceholder() {
        const text = this.getModuleConfig('branch_page.default_select_text')
        return (text == '' || text == null || text == 'null') ? '請選擇區域櫃點' : text
      },
      claimsForWatcher() {
        return _.cloneDeep(this.temporaryBranch);
      },
    },
    data() {
      return {
        branchId: null,
        temporaryBranch: [null],
        branchLevel: [],
        isLastBranch: false,
        fetching: false
      }
    },
    watch: {
      "claimsForWatcher": {
        handler: function (newVal, oldVal) {
          if (newVal.slice(-1)[0] === oldVal.slice(-1)[0]) {
            for (var i = newVal.length - 1; i >= 0; i--) {
              if (newVal[i] !== oldVal[i]) {
                this.temporaryBranch = this.temporaryBranch.slice(0, i+1)
                this.branchLevel = this.branchLevel.slice(0, i+1)
              }
            }
          } else {
            if (newVal.length < oldVal.length) {
              this.branchLevel = this.branchLevel.slice(0, newVal.length+1)
            }
            const checkNullIndex = newVal.indexOf(null)
            if (checkNullIndex !== this.branchLevel.length - 1) {
              if (checkNullIndex !== -1) {
                this.branchLevel = this.branchLevel.slice(0, checkNullIndex + 1)
                this.isLastBranch = false
                if (checkNullIndex !== newVal.length - 1) {
                  this.temporaryBranch = this.temporaryBranch.slice(0, checkNullIndex + 1)
                }
              } else {
                this.branchLevel = this.branchLevel.slice(0, newVal.length)
                this.branchId = newVal.slice(-1)[0]
                this.fetchBranches(false)
              }
            } else {
              this.isLastBranch = false
            }
          }
        },
        deep: true
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      async fetchBranches(init = false) {
        if (!this.branchProvider) {
          alert("請設定分店提供者");
          return;
        }
        this.fetching = true
        //this.staffs = [];
        if (init) {
          this.fetchBranchesForInit();
        } else {
          const providerSetting = this.branchProvider.split(':');
          if ((providerSetting.length === 2)
            || providerSetting[2] === 'true'
            || this.branchProvider === 'waltily.branch'
          ) {
            this.providerOptions[this.branchLevel.slice(-1)[0] + 1] = {}
            const response = await staffApi.getBranches({ type: 'level', branch_id: this.branchId });
            const source = response.data.data
            if (source) {
              const level = source[0].level
              this.providerOptions[level] = {}
              this.providerOptions[level].options = this.sortBranch(source, source[0]['org_id'], this.showBranchCode)
              if (level < this.branchLevel.slice(-1)[0]) {
                this.branchLevel[this.branchLevel.length - 1] = level
              } else if (this.branchLevel.indexOf(level) === -1) {
                this.branchLevel.push(level)
              }
              this.temporaryBranch.push(null)
              this.isLastBranch = false
            } else {
              if (this.branchLevel.length > this.temporaryBranch.filter(Boolean).length) {
                this.branchLevel = this.branchLevel.slice(0, this.temporaryBranch.filter(Boolean).length)
              }
              this.isLastBranch = true
            }
          } else {
            this.isLastBranch = true
          }
        }
        this.fetching = false
        this.$forceUpdate();
      },
      async fetchBranchesForInit() {
        this.providerOptions = []
        let response = null
        const providerSetting = this.branchProvider.split(':');

        let params = {}
        switch (true) {
          case providerSetting[1] === 'all':
            // 全部分店
            params = { type: 'all' }
            break;
          case providerSetting[1] === 'level':
          case providerSetting[1] === undefined:
            // 階級獲取 只是從parent_id - null
            params = { type: 'level', branch_id: null }
            break;
          default:
            // 階級獲取 從特定分店開始
            params = { type: 'specific', branch_code: providerSetting[1] }
            break;
        }

        try {
          response = await staffApi.getBranches(params);

          const source = response.data.data
          const level = source[0].level
          this.providerOptions[level] = {}
          this.providerOptions[level].options = this.sortBranch(source, source[0]['org_id'], this.showBranchCode)
          this.branchLevel.push(level)
        } catch (error) {
          console.log(error);
        }
      },
      async init() {
        await this.fetchBranches(true);
      },
      nextStep() {
        const level = this.branchLevel.slice(-1)
        this.set('branch', {
          id: this.branchId,
          name: this.providerOptions[level].options.find((o) => o.value == this.branchId)?.text
        })
        this.nextStepByList("LiffReservationBranch", {
          branch_id: this.branchId
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.liff-staff-list {

  &__checkbox {
    display: none;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px 16px 16px;
    &.need-bottom {
      margin-bottom: 150px;
    }

    &-item {
      display: flex;
      align-items: center;
      border: 1px solid #E5E5EA;
      border-radius: 10px;
      padding: 12px;
      color: #2C2C2E;
      font-size: 18px;
      font-weight: 500;
      gap: 12px;
      cursor: pointer;
      position: relative;
      &:hover {
        background-color: #F5F5F5;
      }
      &.active {
        border: 2px solid var(--s-primary);
      }
      &-img {
        width: 80px;
        height: 80px;
        border-radius: 50px;
        flex-shrink: 0;
      }
      &-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 12px;
      }
      &-check {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 32px;
        height: 32px;
        padding: 4px;
      }
    }
  }

  &__card {
		border-radius: 10px;
		border: none;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    .card-body {
      padding: 0;
    }
	}

  &__confirm {
    position: fixed;
    max-width: 768px;
    margin: auto;
    background-color: #fff;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    padding: 12px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
    font-size: 13px;
  }
}

.spinner-border {
  border-width: 4px;
}

</style>
